@import url("https://fonts.googleapis.com/css2?family=Arimo&family=Lato&display=swap");

$radius: 5px;
$menubarHeight: 64px;
$primary: #3d5467;
$fancy-gradient: linear-gradient(45deg, #e67229, #d029e6, #294ce6);
$fancy-animation: bg-animation 45s infinite alternate;

html {
  touch-action: manipulation;
}

html,
body {
  font-family: "Lato", sans-serif;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f1edee;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
  // @media screen and (min-width: 480px) {
  //   max-width: 480px;
  //   justify-self: center;
  // }
}

.titlebar {
  & h1 {
    font-family: "Arimo", sans-serif;
    text-align: center;
    text-transform: uppercase;
    margin: 0.65rem;
    padding: 0;
  }
}

.btn {
  background: $primary;
  border: none;
  margin: 1rem;
  padding: 1rem;
  border-radius: $radius;
  color: white;
  &:active {
    background: darken($primary, 10%);
  }
  @media (hover: hover) {
    &:hover {
      background: lighten($primary, 10%);
    }
  }
}

fieldset {
  position: relative;
  margin: 1rem;
  background-color: #3d5467;
  border: none;
  border-radius: $radius;
  color: white;
  & legend {
    text-transform: uppercase;
    font-family: "Arimo", sans-serif;
    font-size: 10px;
    position: absolute;
    bottom: 100%;
    background-color: inherit;
    padding: 0.25rem 0.5rem;
    color: currentColor;
    border-radius: $radius $radius 0 0;
  }
}

.form-control {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
  margin: 2rem 0rem 0rem 0rem;
  border-radius: 0 $radius $radius $radius;
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
  & label {
    text-transform: uppercase;
    font-family: "Arimo", sans-serif;
    font-size: 10px;
    position: absolute;
    bottom: 100%;
    background-color: inherit;
    padding: 0.25rem 0.5rem;
    color: #3d5467;
    border-radius: $radius $radius 0 0;
  }
  & input,
  & select {
    border: none;
    padding: 0.25rem;
    margin: 0.25rem;
    outline: none;
  }
}

.unit-toggle {
  display: inline-flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-left: auto;
  & label {
    font-size: 12px;
    color: white;
  }
  & input {
    margin-left: 0.5rem;
  }
}

.result-table {
  display: flex;
  flex-direction: column;
  background-color: #3d5467;
  margin: 0 1rem 0 1rem;
  margin-top: auto;
  overflow: hidden;
  padding: 0.25rem;

  border-radius: $radius;
  & table {
    width: 100%;
    border-collapse: collapse;
    & tr,
    & td {
      border: none;
    }
    & td {
      color: white;
      padding: 0.25rem;
    }
    .text-center {
      text-align: center;
    }
    .text-right {
      text-align: right;
    }
    & .header {
      font-family: "Arimo", sans-serif;
      font-weight: bold;
      color: white;
      padding: 0.25rem;
      font-size: 12px;
    }
  }
}

section.app-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: calc(1rem + env(safe-area-inset-top));
  margin-bottom: calc(#{$menubarHeight} + env(safe-area-inset-bottom));
}

nav.app-menu {
  height: $menubarHeight;
  background-color: $primary;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: env(safe-area-inset-bottom);
  & button.menu-button {
    flex-basis: 0;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    background: none;
    outline: none;
    border: none;
    border-bottom: 4px solid transparent;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0.5;
    & img.icon {
      color: white;
    }
    &.active {
      opacity: 1;
    }
  }
}

.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.loading-spinner {
  position: relative;
  & .spinner {
    width: 128px;
    height: 128px;
    & span {
      display: block;
      box-sizing: border-box;
      padding: 0.25rem;
      width: 100%;
      height: 100%;
      border: 0.5rem solid $primary;
      border-bottom-color: transparent;
      border-radius: 256px;
      animation: loading-spinner 2500ms linear infinite;
    }
  }
  & span.spinner-text {
    position: absolute;
    top: calc(100% + 1rem);
    text-transform: uppercase;
    color: $primary;
    font-weight: bold;
    left: 50%;
    transform: translateX(-50%);
  }
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

section.about-page {
  height: 100%;
  color: #333;
  padding: 0 1rem;
  h1 {
    font-family: "Arimo", sans-serif;
  }
}

fieldset.language-switcher {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & button {
    flex: 1;
    margin: 0.5rem;
    background: #eee;
    color: $primary;
    border: none;
    outline: none;
    min-height: 64px;
    text-transform: uppercase;
    &.active {
      outline: 4px solid $primary;
      outline-offset: -8px;
    }
  }
}

a.spiffy-link {
  background-image: $fancy-gradient;
  background-size: 500%;
  background-clip: text;
  font-weight: bold;
  animation: $fancy-animation;
  color: white;
  color: transparent;
}

img.teeko-image {
  width: 100%;
}

@keyframes bg-animation {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

.toggle-switch {
  $width: 4rem;
  $height: $width / 2;
  $animation: all 175ms ease-in-out 0s;
  background-color: none;
  position: relative;
  display: inline-block;
  width: $width;
  height: $height;
  transform: scale(0.7);
  &-input {
    display: none;
  }
  &-label {
    display: block;
    width: inherit;
    height: inherit;
    background-color: darken($primary, 10%);
    border-radius: $width;
    transition: $animation;
    overflow: hidden;
    box-shadow: inset 0 0.25rem 0.2rem 0rem rgba(0, 0, 0, 0.35);
  }
  &-button {
    display: block;
    background-color: #eee;
    width: $height;
    height: $height;
    border-radius: $height;
    scale: 0.8;
    margin-left: 0;
    transition: $animation;
  }
  &-input:checked + &-label {
    background-color: lighten($primary, 10%);
    .toggle-switch-button {
      margin-left: calc(100% - #{$height});
    }
  }
}

.settings-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:first-of-type {
    margin-top: .5rem;
  }
  &:not(:last-child) {
    margin-bottom: .75rem;
  }
}